import {
  CONSUMER_CODE_WEEK_SUCCESS,
  CONSUMER_CODE_WEEK_FAILED,
  DashboardDispatchType,
} from '../types/dashboardTypes';

interface IDashboardDefaultState {
  ccwk: { seasonValue: string; dateValue: string }[];
}

const defaultState: IDashboardDefaultState = {
  ccwk: [],
};

const dashboardReducer = (
  state: IDashboardDefaultState = defaultState,
  action: DashboardDispatchType
): IDashboardDefaultState => {
  switch (action.type) {
    case CONSUMER_CODE_WEEK_SUCCESS:
      return {
        ...state,
        ccwk: action.payload,
      };
    case CONSUMER_CODE_WEEK_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
