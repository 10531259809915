export const CONSUMER_CODE_WEEK_SUCCESS = 'CONSUMER_CODE_WEEK_SUCCESS';
export const CONSUMER_CODE_WEEK_FAILED = 'CONSUMER_CODE_WEEK_FAILED';

interface IConsumerCodeWeekFetchSuccess {
  type: typeof CONSUMER_CODE_WEEK_SUCCESS;
  payload: [];
}

interface IConsumerCodeWeekFetchFailed {
  type: typeof CONSUMER_CODE_WEEK_FAILED;
}

export type DashboardDispatchType =
  | IConsumerCodeWeekFetchSuccess
  | IConsumerCodeWeekFetchFailed;

export enum ResponseCode {
  OKAY = 200,
  CREATED = 201,
  ALREADY_EXISTS = 400,
}
