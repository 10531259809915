import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import {
  CONSUMER_CODE_WEEK_SUCCESS,
  CONSUMER_CODE_WEEK_FAILED,
  ResponseCode,
  DashboardDispatchType,
} from '../types/dashboardTypes';
import apiConfig from '../../config/apiConfig';
import apiInstance from '../axios/index';

export const fetchConsumerCodeWeek =
  (dateQueryValue: string) =>
  async (dispatch: Dispatch<DashboardDispatchType>) => {
    try {
      const getConsumerCodeWeekUrl =
        apiConfig.baseURL + apiConfig.getConsumerCodeWeek.url;

      const response = await apiInstance.get(`${getConsumerCodeWeekUrl}`, {
        params: {
          dateValue: dateQueryValue,
        },
      });

      if (response && response.status === ResponseCode.OKAY && response.data) {
        dispatch({
          type: CONSUMER_CODE_WEEK_SUCCESS,
          payload: response.data.response,
        });
      }
    } catch (err) {
      dispatch({
        type: CONSUMER_CODE_WEEK_FAILED,
      });
      toast.error('Something went wrong. Failed to get Comumer Code Week data');
    }
  };

export default fetchConsumerCodeWeek;
